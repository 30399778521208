.section-container-rsvp {
	height: 400px;
	width: 100%;
  }
  
  .overlay-rsvp {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index: 2;
	backdrop-filter: blur(3px);
  }
  
  .overlay-rsvp h1 {
	font-size: 2.5em;
	color: white;
	margin: 0;
  }
  