/* Styles for the main container */
.mobile-content, .desktop-content {
  position: relative;
  border-radius: 10px;
  margin: 0 auto;
  padding: 30px;
  width: 70%;
  max-width: 1200px;
  z-index: 3;
  overflow: hidden;
}

.blur {
  background-color: rgba(70, 55, 0, 0.6);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
}

.contents{
  position: relative;
  z-index: 3;
}

/* Mobile-specific styles */
.mobile-content {
  width: 100%;
  padding: 15px 0;
}

/* Desktop-specific styles */
@media (min-width: 769px) {
  .desktop-content {
    width: 70%;
    padding: 30px;
  }
}
