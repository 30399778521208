@font-face {
  font-family: "Precious"; 
  src: url("./assets/fonts/Precious.woff2"); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "faradila"; 
  src: url("./assets/fonts/faradila.ttf"); 
  font-weight: normal;
  font-style: normal;
}

body{
  margin: 0;
  padding: 50px 0;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.welcome-text {
  font-size: 2em;
  margin: 0 0 40px;
  line-height: 1.5;
}

.continue-button {
  background-color: rgb(255, 215, 0);
  color: rgb(0, 0, 0);
  padding: 15px 30px;
  font-size: 1.2em;
  border: 3px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.continue-button:hover {
  background-color: rgb(255, 225, 50);
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 768px) {
  .welcome-text {
      font-size: 1.3em;
  }

  .continue-button {
      font-size: 1em;
      padding: 10px 20px;
  }
}

.no-scroll {
  overflow: hidden;
}