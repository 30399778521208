/* Fullscreen image */
.media-viewer-image {
	/* width: 100%; */
	max-height: 100%;
	max-width: 100%;
	/* width: auto; */
	height: auto;
	object-fit: contain;
}
  
/* Video styling */
.media-viewer-video {
	/* width: 100%; */
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.spacer{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	flex: 0 0 100%;
	overflow: hidden;
}

/* Unsupported media message */
.media-viewer-unsupported {
	color: white;
	font-size: 18px;
}

/* Fullscreen image or video - centered and zoomable */
.media-viewer-image,
.media-viewer-video {
  transition: transform 0.2s ease-out; /* Smooth zooming effect */
  transform-origin: center center; /* Keep media centered when zooming */
}
