.progress-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 60px;
	padding: 0 20px;
	background-color: transparent;
	opacity: 1;
	transition: opacity 0.5s ease;
	z-index: 10;
	background-color: lightslategray;
}

@media screen and (max-width: 630px) {
	.progress-container {
		height: 100px;
	}
	
}

.progress-container.hide {
	opacity: 0;
	pointer-events: none;
}

.progress-bar {
	position: relative;
	width: 100%;
	height: 6px;
	background-color: #ccc;
}

.progress-bar-inner {
	position: absolute;
	height: 100%;
	background-color: #DA3;
	width: 0%;
	transition: width 0.5s ease;
}

.upload-progress-bar-inner {
	position: absolute;
	height: 100%;
	background-color: #32a89e;
	width: 0%;
	transition: width 0.5s ease;
}

.progress-text {
	color: #000;
	margin-bottom: 10px;
	margin-top: 10px;
	font-size: 20px;
	text-align: left;
}