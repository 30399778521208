/* Fullscreen black background */
.media-viewer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
	overflow: hidden; /* Prevent overflow on the entire viewer */
	backdrop-filter: blur(3px);
}
  
/* The content container for the media (photo or video) and close button */
.media-viewer-content {
	position: relative;
	width: 90vw;  /* Define container width based on viewport width */
	height: 90vh; /* Define container height based on viewport height */
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden; /* Ensure content never overflows */
}

/* Close button in the upper-right corner */
.close-btn {
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 40px;
	background: transparent;
	border: none;
	color: white;
	cursor: pointer;
	z-index: 3000;
}

.track-container{
	overflow-x: hidden;
	height: 100%;
}
.track{
	display: flex;
	flex-direction: row;
	height: 100%;
}