.section-container-ms {
	position: relative;
	height: 1000px;
	width: 100%;
	overflow: hidden;
}

.wedding-bg {
	width: 100%;
	height: 1000px;
	position: absolute;
	top: 50%;
	background-image: url('https://d16gku0mdgm9y9.cloudfront.net/uploads/d8997814-5fb2-491b-b800-dffd2a08e210/compressed/portraits/7M301079.jpeg');
	/* Using mainImage */
	background-position: 55% 30%;
	background-repeat: no-repeat;
	/* background-size: cover; */
	background-size: 250%;
	transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
	.wedding-bg {
		background-size: cover;
	}
	
}


.overlay-ms {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: opacity 2s ease-in-out;
	opacity: 0;
}

.subheading {
	color: white;
	font-weight: 300;
	font-family: "Script MT", "Segoe script", "Rage", "Lucida Handwriting", cursive;
	font-size: 1.3em;
}

.msh1 {
	font-size: 8em;
	color: #EB3;
	padding: 20px;
	border-radius: 30px;
	background-clip: text;
	opacity: 0.6;
	margin: 0;
	font-family: "faradila";
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}